export default {
  cancel: 'Cancel',
  select: 'Select',
  hotels: 'Hotels',
  experiences: 'Experiences',
  flights: 'Flights',
  destination: 'Destination',
  origin: 'Origin',
  select_dates: 'Select your arriving and leaving dates',
  arrive_leave_dates: 'Arriving and Leaving Dates',
  all_inclusive: 'All inclusive',
  breakfast_inclusive: 'Breakfast Inclusive',
  seaside: 'Seaside',
  pool: 'Pool',
  ac: 'AC',
  bar: 'Bar',
  restaurant: 'Restaurant',
  exceptional: 'Exceptional',
  opinions: 'opinions',
  guest: 'Guest | Guests',
  room: 'Room | Rooms',
  adults: 'Adults',
  minors: 'Minor | Minors',
  minor_age: 'Minor {count}',
  age: 'Age',
  incomplete_ages: 'Minors ages are not filled',
  years: 'Year | Years',
  language: 'Language',
  currency: 'Currency',
  search: 'Search',
  price: 'Price',
  from: 'From',
  to: 'To',
  filters: 'Filters',
  order_by: 'Order By',
  name: 'Name',
  search_experiences: 'Search Experiences',
  categories: 'Categories',
  empty_experiences: 'We seem to be experiencing bad weather and we haven\'t found any experiences available for your search.',
  empty_hotels: 'We seem to be experiencing bad weather and we haven\'t found any hotels available for your search.',
  empty_transfers: 'We seem to be experiencing bad weather and we haven\'t found any transfers available for your search.',
  continue_payment: 'Continue with payment',
  order_error: 'You must fill out all the fields of the form to make the reservation.',
  reservation_holder: 'Reservation holder',
  last_name: 'Last Name',
  country: 'Country',
  contact_info: 'Contact information',
  agency: 'Agency',
  agent: 'Agent',
  email: 'Email',
  phone: 'Phone',
  i_agree: 'I agree with',
  terms_and_conditions: 'Term and Conditions',
  commission: 'Commission',
  reservation_summary: 'Reservation summary',
  date: 'Date',
  restrictions: 'Restrictions',
  included: 'Included',
  recommendations: 'Recommendations',
  contact: 'Contact',
  book: 'Checkout',
  total: 'Total',
  confirm_reservation: 'Confirm reservation',
  congrats: 'Congratulations! The reservation with number <b>{order}</b> has been generated successfully. Confirm your reservation by generating your payment, if you have questions, contact us.',
  no_destination: 'Select a destination',
  no_origin: 'Select an origin',
  promotions: 'Promotions',
  promotions_1: 'Discover the perfect getaway with our exclusive promotions',
  promotions_2: 'Book stays, experiences and transfers with the best commissions',
  survey: 'Your opinion is important to us. Would you mind sharing your feedback in our quick quality survey?',
  click_here: 'Click here',
  survey_url: 'https://survey.hsforms.com/1h6a0lUNgRFWt8S5SSAuq2g3jdul',
  rewards: 'Rewards',
  asset_program: 'Enter the ASSET rewards portal and check your benefits obtained from the reservations generated. Take control and redeem your achievements that the program offers you.',
  asset_button: 'Go to rewards site',
  open_details: 'Open details',
  search_hotels: 'Search Hotels',
  search_transfers: 'Search Transfers',
  available_rooms: 'Available Rooms',
  total_from: 'Total from',
  choose_plan: 'Choose Plan',
  room_unavailable: 'Room Not Available',
  retry_payment: 'Retry Payment',
  bad_weather: 'Oops, Looks like we\'re <br> experiencing bad weather.',
  payment_no_success: 'Your payment process has not been successful',
  try_payment_again: 'Please try again or try another method.',
  show_commissions: 'Show commissions',
  logout: 'Logout',
  thanks: 'Thank you for your purchase',
  enjoy: 'Enjoy your visit',
  back_home: 'Back to home',
  your_reservation: 'Your reservation number is: <b>{order}</b>',
  invalid_credentials: 'Your credentials are not valid.',
  login: 'Login',
  privacy_policy: 'PRIVACY POLICY',
  hotels_in: 'Hotels in',
  all_category: 'All experiences',
  see_all: 'See All',
  credit_card: 'Credit Card',
  wire_transfer: 'Bank Transfer',
  complete_reservation: 'Complete reservation',
  discount: 'Discount',
  select_discount: 'Select Discount',
  remove_discount: 'Remove discount',
  remove: 'Remove',
  tax_included: 'Taxes included',
  select_agency: 'Select an agency',
  book_with: 'Book with ',
  down_payment: 'Down Payment',
  non_refundable: 'Non-refundable',
  refundable_rate: 'Flexible Rate',
  password: 'Password',
  confirm_password: 'Confirm password',
  invalid_url: 'Invalid or expired url',
  setup_your_password: 'Hi <b>{name}</b>, Let set up your new password.',
  password_must: 'Password must contain:',
  password_size: 'At least 8 characters.',
  password_upper: 'Both uppercase and lowercase letters.',
  password_special: 'At least 1 special character.',
  password_digit: 'At least 1 digit.',
  password_match: 'Password confirmation needs to match.',
  set_password: 'Set password',
  go_to_login: 'Go to login',
  password_congrats: 'Great, <b>{name}</b>. Your new password has been set up correctly. Click the "Go to login" button to sign in to your account.',
  transfers: 'Transfers',
  transfer: 'Transfer',
  book_from_airport: 'Book your transfer from the airport.',
  search_transfer: 'Find your transfer',
  departure_date: 'Departure Date',
  return_date: 'Return Date',
  one_way: 'One-way',
  round: 'Roundtrip',
  transport_type: 'Transport Type',
  page_not_found: 'Page Not Found',
  server_error: 'Server error',
  try_again: 'Try Again',
  manage_agency: 'Manage agency',
  my_agency: 'My agency',
  reservations: 'Reservations',
  commissions: 'Commissions',
  agents: 'Agents',
  paid: 'Paid',
  pending: 'Pending',
  beneficiary: 'Reservation beneficiary',
  check_in: 'Check-in',
  check_out: 'Check-out',
  nights: 'Night | Nights',
  plan: 'Plan',
  payment: 'Payment',
  reservation_details: 'Reservation Details',
  search_by_name_or_email: 'Search by order number',
  order: 'Order',
  summary: 'Summary',
  total_to_pay: 'Total to pay',
  pending_payment: 'Pending payment',
  confirmation_code: 'Confirmation',
  operation_date: 'Operation date',
  description: 'Description',
  reservation_items: 'Reservation items',
  item_number: 'Item number',
  reserved: 'Reserved',
  cancelled: 'Cancelled',
  paid_status: 'Paid',
  operated: 'Operated',
  requested: 'Requested',
  no_show: 'No Show',
  audited: 'Audited',
  modified: 'Modified',
  annulled: 'Annulled',
  blocked: 'Blocked',
  booked: 'Booked',
  my_reservations: 'Reservations',
  advanced_search: 'Advanced Search',
  reservation_date: 'Reservation date',
  product: 'Product',
  reservation_status: 'Reservation Status',
  confirmation_status: 'Confirmation Status',
  payment_status: 'Payment Status',
  payment_method: 'Payment Method',
  not_paid: 'Not Paid',
  confirmed: 'Confirmed',
  clear_filters: 'Clear Filters',
  apply_filters: 'Search',
  services: 'Services',
  order_number: 'Order number',
  confirmed_services: 'Confirmed services',
  booking: 'Booking',
  amount: 'Amount',
  balance: 'Balance',
  item: 'Item|Items',
  of: 'of',
  created_at: 'Created at ',
  by: 'by',
  arrive: 'Arriving',
  leave: 'Leaving',
  remember_me: 'Remember me',
  passengers: 'Passenger | Passengers',
  estimate_duration: 'Estimated duration per trip: {duration}',
  max_passengers: 'Up to {count} passengers',
  max_suitcases: 'Up to {count} medium size',
  door_to_door: 'Door to door service',
  transfer_cancel_policy: 'Cancellations or modifications 48 hours. before at no cost.',
  airline: 'Airline',
  flight_number: 'Flight number',
  arrival_time: 'Arrival time',
  departure_time: 'Departure time',
  notes: 'Notes',
  flight_info: 'Flight Information',
  arriving: 'Arriving',
  arrival: 'Arrival',
  departure: 'Departure',
  pick_up: 'Pick up',
  international_flight: 'International Flight',
  first_trip: 'First trip',
  second_trip: 'Second trip',
  commission_pending: 'Commission pending',
  commission_discount: 'Commission discount',
  resend_invite: 'Resend invitation',
  resend_error: 'The invitation could not be sent, try again.',
  resend_success: 'The invitation has been sent successfully.',
  email_error: 'Invalid email address.',
  phone_error: 'Invalid phone or mobile number. Maximum 10 digits.',
  more_images: 'More images',
  book_hotel: 'Book Hotel',
  see_details: 'See details',
  stay: 'Stay',
  travel_complements: 'Travel Complements',
  find_more_experiences: 'Find more experiences by category',
  category: 'Category',
  average_price: 'Average rate per person for',
  average_per_person: 'Average per person',
  select_date: 'Select date',
  add: 'Add',
  quote: 'Quote',
  continue_reservation: 'Continue reservation',
  check_reservation: 'Review Reservation',
  subtotal: 'Subtotal',
  select_origin: 'Select the origin airport.',
  add_transfer: 'Add Transfer',
  active: 'Active',
  inactive: 'Inactive',
  status: 'Status',
  add_agent: 'Add Agent',
  mobile: 'Mobile',
  save: 'Save',
  update_agent: 'Edit Agent',
  agent_error: 'The agent could not be saved, check the information and try again.',
  cross_sell_text: 'Make your stay unforgettable! We offer exclusive add-ons so you can make the most of your trip. Choose from comfortable transfers and exciting activities to add a special touch to your experience. <b>These add-ons are optional </b>and designed to provide you with greater comfort and fun during your stay.',
  forgot_password: 'Forgot your password?',
  send: 'Send',
  forgot_instructions_1: 'Enter your email address and we will send you a link to reset your password.',
  forgot_instructions_2: 'If you do not receive the email, check your spam folder or try again.',
  password_email_sent: 'An email has been sent to your inbox with instructions to reset your password.',
  my_commissions: 'My Commissions',
  pending_payments: 'Pending Payments',
  received_payments: 'Paid Payments',
  pending_commissions: 'Pending Commissions',
  paid_commissions: 'Paid Commissions',
  payment_date: 'Payment Date',
  total_pending_commissions: 'Total Pending Commissions',
  estimated_payment_date: 'Estimated Payment Date',
  payment_amount: 'Payment Amount',
  reservations_number: 'Reservations Amount',
  details: 'Details',
  included_reservations: 'Included Reservations',
  hotel: 'Hotel',
  experience: 'Experience',
  percentage: 'Percentage',
  commission_amount: 'Commission Amount',
  total_commissions: 'Total Commissions',
  total_paid_commissions: 'Total Paid Commissions',
  payment_reference: 'Reference'
}
