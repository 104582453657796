import { createGlobalState, useStorage } from '@vueuse/core'
import { computed } from 'vue'
import http from '@/http.js'
import useHotelFilters from '@/store/hotelFilters.js'
import { useRoute } from 'vue-router'

export default createGlobalState(() => {
  const hotelRate = useStorage('cart-hotel', {})
  const selectedTransfers = useStorage('cart-transfers', [])
  const selectedExperiences = useStorage('cart-experiences', [])

  const { getHotelParams, startDateDetails, endDateDetails, paxDetails, totalNights } = useHotelFilters()
  const route = useRoute()
  const cartTotal = computed(() => {
    const rawPrice = stringToNumber(hotelRate.value.rate?.discount_price)
    let total = selectedTransfers.value.reduce((acc, transfer) => acc + stringToNumber(transfer.total), 0) + selectedExperiences.value.reduce((acc, experience) => acc + stringToNumber(experience.total), 0) + rawPrice
    total = total.toFixed(2)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)
  })

  const cartTotalFinal = computed(() => {
    const discountPrice = hotelRate.value.rate?.discount_price_after_commission ?? hotelRate.value.rate?.discount_price
    const rawPrice = stringToNumber(discountPrice)
    let total = selectedTransfers.value.reduce((acc, transfer) => acc + stringToNumber(transfer.total), 0) + selectedExperiences.value.reduce((acc, experience) => acc + stringToNumber(experience.total), 0) + rawPrice
    total = total.toFixed(2)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)
  })

  function stringToNumber (value) {
    return Number(value.replace(/[^0-9.-]+/g, ''))
  }

  async function fetchHotelRate () {
    const { data } = await http().get('hotels/rate', {
      params: {
        ...getHotelParams(route.params.id),
        room_id: route.query.room_id,
        room_code: route.query.room_code,
        rate_code: route.query.rate_code
      }
    })
    data.start_date = startDateDetails
    data.end_date = endDateDetails
    data.total_nights = totalNights
    data.pax = paxDetails
    hotelRate.value = data
  }

  function clearCart () {
    hotelRate.value = {}
    selectedTransfers.value = []
    selectedExperiences.value = []
  }

  return {
    hotelRate,
    selectedTransfers,
    selectedExperiences,
    fetchHotelRate,
    startDateDetails,
    endDateDetails,
    paxDetails,
    cartTotal,
    clearCart,
    cartTotalFinal
  }
})
